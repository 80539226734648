import {
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Nav,
  Button,
  Badge,
  NavItem,
  Fade
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useProfile } from '@/context/profile.context';
import {
  accountLinks,
  helpLinks,
  rentalsLinks
} from '@/components/dashboard/dashboard-routes';
import { PrivateProfileDTO } from '@b2w/shared/types';
import { useToggler } from '@/custom-hooks/useToggler';
import roleManager from '@/lib/profile/role.manager';
import useTranslation from 'next-translate/useTranslation';
import AppLink from '@/components/AppLink';
import { useUserTempData } from '@/context/usertempdata.context';
import { useUser } from '@/context/user.context';
import ContentLoader from 'react-content-loader';
import LinkToPrivate from '@/components/LinkToPrivate';
import YourAccSuspendedMsg from '@/components/YourAccSuspendedMsg';
import { profileService } from '@/lib/services/profile.service';
import { theme } from '@/styles/sc-theme';
import { useRouter } from 'next/router';
import {
  AffPageTab,
  affTabHref
} from '@/components/affiliate-dashboard/affiliate-page.utils';
import ChangeCurrencyBtn from '@/components/currency-changer/ChangeCurrencyBtn';
import { getTotalFromObject } from '@/lib/totalFromObj';
import BellDropdown from '@/components/bell/BellDropdown';

const renderAdminLink = (profile: PrivateProfileDTO) => {
  if (roleManager.canAccessAdmin(profile)) {
    return (
      <>
        <DropdownItem divider />

        <DropdownItem className="p-0 desktop-dropdown-item">
          <AppLink
            href="/_admin"
            className="d-block link-unstyled text-black h8 mb-0 py-1 px-3"
          >
            Admin panel
          </AppLink>
        </DropdownItem>
      </>
    );
  }

  return null;
};

const renderAffiliateLink = (profile?: PrivateProfileDTO) => {
  if (profile?.affiliateId) {
    return (
      <DropdownItem className="p-0 desktop-dropdown-item">
        <AppLink
          href={affTabHref(AffPageTab.dashboard)}
          className="d-block link-unstyled text-black h8 mb-0 py-1 px-3"
        >
          Affiliate dashboard
        </AppLink>
      </DropdownItem>
    );
  }

  return (
    <DropdownItem className="p-0 desktop-dropdown-item">
      <AppLink
        href="/affiliate"
        className="d-block link-unstyled text-black h8 mb-0 py-1 px-3"
      >
        Become an affiliate
      </AppLink>
    </DropdownItem>
  );
};

const DesktopLink = ({ href, text }) => {
  const router = useRouter();

  if (router.pathname === '/search' && href === '/search') {
    // force full refresh by using native anchor
    return (
      <NavLink href="/search" className="text-primary-light font-weight-bold">
        {text}
      </NavLink>
    );
  }

  return (
    <AppLink href={href} passHref legacyBehavior>
      <NavLink className="text-primary-light font-weight-bold">{text}</NavLink>
    </AppLink>
  );
};

const DropdownHeader = ({ children }) => {
  return (
    <DropdownItem
      header
      className="h9 text-secondary mb-0 px-3 pt-1 pb-2 text-uppercase"
    >
      {children}
    </DropdownItem>
  );
};

const DropdownBlock = ({ header, links }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <DropdownHeader>{header}</DropdownHeader>
      {links.map((el) => (
        <DropdownItem key={el.i18nKey} className="p-0 desktop-dropdown-item">
          <AppLink
            href={el.href}
            className="d-block link-unstyled text-black h8 mb-0 py-1 px-3"
          >
            {t(`menu.${el.i18nKey}`)}
          </AppLink>
        </DropdownItem>
      ))}
    </>
  );
};

type Props = {
  handleSignOut: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const DesktopNavs = ({ handleSignOut }: Props) => {
  const { t } = useTranslation('common');
  const { user, isLoggedOut, isAlreadyLoggedIn } = useUser();
  const { profile } = useProfile();
  const { unreadCounters } = useUserTempData();

  const [isAccountToggled, toggleAcc] = useToggler(false);

  const renderMarkup = () => {
    if (isLoggedOut) {
      return (
        <Fade in className="d-flex align-items-center">
          {/* <ChangeLanguageBtn /> */}
          <ChangeCurrencyBtn />
          <LinkToPrivate href="/dashboard/vehicles/add" passHref legacyBehavior>
            <NavLink className="text-primary-light font-weight-bold">
              {t('navbar.rentout')}
            </NavLink>
          </LinkToPrivate>
          <DesktopLink href="/signin" text={t('navbar.login')} />
          <DesktopLink href="/signup" text={t('navbar.register')} />
        </Fade>
      );
    }

    const totalUnreadSupportMessages = getTotalFromObject(
      unreadCounters?.support_inbox_unread_messages
    );
    const totalUnreadChatMessages = getTotalFromObject(
      unreadCounters?.chat_unread_messages
    );

    if (isAlreadyLoggedIn || user) {
      let avatar = (
        <span
          style={{
            display: 'inline-block',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            border: '1px solid #f1f1f1'
          }}
        >
          &nbsp;
        </span>
      );
      if (profile) {
        avatar = (
          <Fade in tag="span">
            <img
              src={profile.avatar}
              alt="user"
              width={30}
              height={30}
              className="rounded-circle"
            />
          </Fade>
        );
      }

      return (
        <Nav tag={Fade} in>
          <div className="d-flex align-items-center">
            <ChangeCurrencyBtn />
            <DesktopLink
              href="/dashboard/vehicles/add"
              text={t('navbar.rentout')}
            />
          </div>

          <div className="d-none d-lg-flex align-items-center justify-content-center">
            <BellDropdown />
          </div>

          <AppLink
            href="/inbox"
            passHref
            className="d-flex align-items-center link-unstyled"
          >
            <Button
              color="link"
              className="d-flex align-items-center py-2 px-2 text-primary-light"
              title="Inbox messages"
            >
              <FontAwesomeIcon
                icon={['fas', 'comments']}
                fixedWidth
                size="lg"
              />
              {totalUnreadChatMessages > 0 && (
                <Badge color="primary-light">{totalUnreadChatMessages}</Badge>
              )}
            </Button>
          </AppLink>
          <Dropdown
            inNavbar
            isOpen={isAccountToggled}
            toggle={toggleAcc}
            className="py-2 pl-2"
          >
            <DropdownToggle
              tag="div"
              data-toggle="dropdown"
              aria-expanded={isAccountToggled}
              className="cursor-pointer tw-flex tw-items-center"
            >
              {avatar}
              <FontAwesomeIcon
                icon="caret-down"
                className="ml-1 text-secondary"
              />
              {totalUnreadSupportMessages > 0 && (
                <Badge color="dark" className="ml-1">
                  {totalUnreadSupportMessages}
                </Badge>
              )}
            </DropdownToggle>

            <DropdownMenu
              right
              className="text-left py-1 overflow-y-auto"
              style={{
                maxHeight: `calc(100vh - ${theme.navbarHeight}px)`
              }}
            >
              {profileService.isProfileSuspended(profile) && (
                <>
                  <div className="px-3 py-1">
                    <YourAccSuspendedMsg onLinkClick={toggleAcc} />
                  </div>
                  <DropdownItem divider />
                </>
              )}
              <DropdownItem className="pt-2 pb-1 px-3 link-unstyled desktop-dropdown-item">
                {profile ? (
                  <AppLink
                    href={`/user/${profile.id}`}
                    prefetch={false}
                    className="link-unstyled"
                  >
                    <div className="h8 mb-0 font-weight-500 text-disappear">
                      {`${profile.firstName} ${profile.lastName}`}
                    </div>
                    <div className="h8 mb-0 text-muted">
                      {t('menu.view_profile')}
                    </div>
                  </AppLink>
                ) : (
                  <ContentLoader speed={2} viewBox="0 0 100 25">
                    <rect x="0" y="0" rx="5" ry="5" width="100" height="25" />
                  </ContentLoader>
                )}
              </DropdownItem>
              {renderAdminLink(profile)}
              <DropdownItem divider />
              <DropdownBlock header="Vehicle rental" links={rentalsLinks} />
              <DropdownItem divider />
              <DropdownBlock header="Account" links={accountLinks} />
              <DropdownItem className="p-0 desktop-dropdown-item">
                <AppLink
                  href="/dashboard/b2wpoints"
                  className="d-flex justify-content-between link-unstyled text-black h8 mb-0 py-1 px-3"
                >
                  <span className="mr-3">B2W points</span>
                  {profile ? (
                    <span>
                      {profile.b2wPoints.earnedCurrent}
                      <FontAwesomeIcon icon="coins" className="ml-1" />
                    </span>
                  ) : (
                    <ContentLoader
                      speed={2}
                      viewBox="0 0 50 20"
                      height={20}
                      uniqueKey="mobile-menu-points"
                      style={{ maxWidth: '100%' }}
                    >
                      <rect x="0" y="0" rx="5" ry="5" width="50" height="20" />
                    </ContentLoader>
                  )}
                </AppLink>
              </DropdownItem>
              {renderAffiliateLink(profile)}
              <DropdownItem divider />
              <DropdownHeader>Help</DropdownHeader>
              {helpLinks.map((el) => (
                <DropdownItem
                  key={el.href}
                  className="p-0 desktop-dropdown-item"
                >
                  <AppLink
                    href={el.href}
                    className="d-flex align-items-center link-unstyled text-black h8 mb-0 py-1 px-3"
                  >
                    {el.text}
                    <span className="ml-2">
                      {totalUnreadSupportMessages > 0 &&
                        el.href.includes('support') && (
                          <Badge color="dark">
                            {totalUnreadSupportMessages}
                          </Badge>
                        )}
                    </span>
                  </AppLink>
                </DropdownItem>
              ))}
              <DropdownItem divider />
              <DropdownItem
                className="h8 mb-0 py-1 px-3 desktop-dropdown-item"
                onClick={handleSignOut}
              >
                {t('navbar.logout')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
      );
    }

    return null;
  };

  return (
    <div className="d-none d-lg-flex justify-content-between align-items-center flex-fill ml-3">
      <Nav>
        <NavItem>
          <DesktopLink href="/search" text={t('navbar.rental')} />
        </NavItem>
        <NavItem>
          <DesktopLink href="/contact" text={t('navbar.contact')} />
        </NavItem>
      </Nav>
      {renderMarkup()}
    </div>
  );
};

export default DesktopNavs;
