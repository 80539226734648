var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"ZO_WTXjJY-0drYCj0zy9E"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

const isProd = process.env.NODE_ENV === 'production';

if (isProd) {
  const SENTRY_DSN =
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
  const isStage =
    process.env.GCLOUD_PROJECT === 'b2wdev-4b01e' ||
    process.env.NEXT_PUBLIC_HOST.includes('b2wdev-4b01e');
  const environment = isStage ? 'staging' : 'production';

  Sentry.init({
    dsn: SENTRY_DSN,
    environment
  });
}
